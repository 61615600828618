$tablet-landscape-min: 1024px;
$tablet-landscape-max: 1366px;
$tablet-portrait-min: 768px;
$tablet-portrait-max: 1024px;
$mobile-landscape-min: 667px;
$mobile-landscape-max: 932px;
$mobile-portrait-min: 390px;
$mobile-portrait-max: 767px;

:root {
    --game-color: #FFF7AE;
    --font-family: "Noto Sans";
    --bottom-height-percent-landscape: 8%;
    --bottom-height-percent-portrait: 8%;
}

@media screen and (max-width: $mobile-landscape-max) and (orientation: landscape) {
    :root {
        --bottom-height-percent-landscape: 8%;
    }
}

@media screen and (min-width: 1181px) and (orientation: landscape) {
    :root {
        --bottom-height-percent-landscape: 8%;
    }
}
.menu-wrap {

  .item {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #fff;
    margin: 10px;
    cursor: pointer;
    -webkit-text-stroke: 1px #464646;
    font-weight: 900;

    button {
      scale: 1 !important;
    }

    @media (pointer: fine) {
      &:hover {
        scale: 1.05;
      }
    }
    &:active {
      scale: 0.95;
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

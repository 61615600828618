.loadScreenWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 30%;
  background-color: #000;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
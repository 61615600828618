@import '../../../assets/styles/variables';
.info {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  font-family: "Noto Sans";
  font-weight: 700;

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }

  &__title {
    display: flex;
    justify-content: center;
    font-size: 40px;
    line-height: 54px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 900;
    -webkit-text-stroke: 2px #464646;
    
    span {
      display: flex;
      align-items: center;
      max-width: 0;
    }
    @media screen and (orientation: landscape) {
      align-self: center;
      width: 25%;
    }

    @media screen and (orientation: portrait) {
      padding: 11vh 0 5vh;
      span {
        max-width: 100%;
        justify-content: center;
      }
      
    }

    //tablet
    @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
      font-size: 20px;
      line-height: 27px;
      -webkit-text-stroke: 1px #464646;
    }
    @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
      font-size: 20px;
      line-height: 27px;
      -webkit-text-stroke: 1px #464646;
    }
  }
  .container {
    height: 100%;
    width: 75%;
    padding-right: 25%;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 30px;

    @media screen and (orientation: portrait) {
      padding-right: 0;
      width: 100%;
      height: 70%;
    }

    &::-webkit-scrollbar-track {
      background-color: #010101;
    }

    &::-webkit-scrollbar {
      width: 8px;
      
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        width: 13px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        width: 13px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--game-color);
    }
  }
}

.title {
  color: var(--game-color);
  font-size: 32px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;
  -webkit-text-stroke: 1px #464646;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    -webkit-text-stroke: 2px #464646;
    font-size: 44px;
    margin: 64px 0;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    -webkit-text-stroke: 2px #464646;
    font-size: 44px;
    margin: 64px 0;
  }
}

.subtitle {
  color: #fff;
  font-size: 24px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 32px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin: 64px 0;
  }
}

.row {
  display: flex;
  margin: 16px 0;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }
  
  .col {
    width: 100%;
    padding:0 16px;
  }
}

.p {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: left;
  margin: 0 16px 10px;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 26px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 26px;
  }

  &.center {
    text-align: center;
  }

  span {
    color: var(--game-color);
  }
}

.image {
  display: flex;
  flex-direction: column;
  
  img,
  svg {
    display: block;
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
  }

  &__text {
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
}

.color {
  color: var(--game-color);
}

.paytable-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 900;

  &__item {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0 8px 16px;


    .img {
      display: block;
      width: 50%;
      position: relative;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .content {
      white-space: nowrap;
      width: 50%;
      padding-left: 8px;
      align-items: flex-start;
      justify-content: center;
      font-size: 14px;
      font-weight: 900;
      color: var(--game-color);
      -webkit-text-stroke: 1px #464646;
      
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 20px;
        -webkit-text-stroke: 1.5px #464646;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 20px;
        -webkit-text-stroke: 1.5px #464646;
      }

      .multiplier {
        display: inline-block;
        margin-right: 4px;
        color: #fff;
        text-transform: uppercase;
        
      }
    }
  }

  &.wild {
    display: flex;

    .paytable-list__item {
      flex-direction: column;
      width: 50%;
      padding-bottom: 32px;
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        padding-bottom: 64px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        padding-bottom: 64px;
      }

      .img {
        width: 100%;
        margin-bottom: 8px;
        
        .image__title {
          color: #fff;
          text-align: center;
          font-size: 20px;
          margin-bottom: 8px;
          @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
            font-size: 32px;
          }
          @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
            font-size: 32px;
          }
        }
      }
      .content {
        width: auto;
        padding: 0;
        font-size: 16px;
        @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
          font-size: 26px;
        }
        @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
          font-size: 26px;
        }
      }
    }
    
  }
  .additional {
    color: #fff;
    font-size: 16px;
    text-align: center;
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      font-size: 26px;
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      font-size: 26px;
    }
  }
}

.payLines {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
}

.buttonBehaviors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  &__item {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #fff;
    padding: 0 8px 16px;
    text-align: center;


    svg {
      display: block;
      width: 56px;
      height: 56px;
      margin-bottom: 8px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

    }
    &.start {
      width: 100%;
      svg {
        width: 210px;
      }
    }
    &.spin {
      svg {
        width: 128px;
        height: 128px;
      }
    }
    &.stop {
      svg {
        width: 128px;
        height: 128px;
      }
    }

    @media screen and (orientation: portrait) {
      &.start,
      &.spin,
      &.stop {
        margin: 0 !important;
      }
    }
  }
}

.buy_feature {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 115px;
  margin: auto;
  &__img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 115px;
    height: 115px;
  }
  &__text {
    position: relative;
    text-align: center;
    font-size: 20px;
    max-width: 65%;
    line-height: 1.2;
    color: #FFFDC7;
    text-shadow: -1px -1px 0 #DD1A79, 1px -1px 0 #DD1A79, -1px 1px 0 #DD1A79, 1px 1px 0 #DD1A79;
  }
}
@import './variables.scss';
@import './popup.scss';

* {
  user-select: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  font-style: normal;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
  margin: 0 auto;
  text-align: center;
}


.svg-image {
  display: block;
  width: 100%;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.svg-color {
  fill: var(--game-color);
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

